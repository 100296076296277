import './api/initial';
import './api/auth';
import './api/wallet';
import './api/staking';
import './api/dapps';
import './api/swap';
import './api/tokens';
import './api/transfer';
import './api/nfts';
import './api/vesting';
import './api/cards';
import './api/notifications';
import './api/activity';
import './apiUpdates/initial';
import './apiUpdates/activities';
import './apiUpdates/dapp';
import './ui/initial';
import './ui/misc';
import './ui/dapps';
import './ui/settings';
import './ui/nfts';
import './ui/vesting';
import './ui/tokens';
import './ui/transfer';
import './ui/shared';
import './ui/notifications';
import './ui/cards';
