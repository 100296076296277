import React, { memo, useState } from '../../lib/teact/teact';
import { getActions } from '../../global';

import type { ApiChain, ApiImportAddressByChain } from '../../api/types';
import type { LangFn } from '../../util/langProvider';

import { IS_CORE_WALLET } from '../../config';
import { stopEvent } from '../../util/domEvents';
import { isValidAddress } from '../../util/isValidAddressOrDomain';

import useFlag from '../../hooks/useFlag';
import useLang from '../../hooks/useLang';
import useLastCallback from '../../hooks/useLastCallback';

import Button from '../ui/Button';
import Input from '../ui/Input';
import Modal from '../ui/Modal';

import modalStyles from '../ui/Modal.module.scss';

interface OwnProps {
  isOpen: boolean;
  onClose: () => void;
}

function SettingsImportAccount({ isOpen, onClose }: OwnProps) {
  const lang = useLang();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      noBackdropClose
      isCompact
      title={lang('Add Readonly Wallet')}
    >
      <ImportAccountContent onClose={onClose} />
    </Modal>
  );
}

export default memo(SettingsImportAccount);

function ImportAccountContent({ onClose }: Pick<OwnProps, 'onClose'>) {
  const { importReadonlyAccount } = getActions();

  const lang = useLang();
  const inputs = {
    ton: useInput((address) => validateAddressInput(address, 'ton', true, lang)),
    tron: useInput((address) => validateAddressInput(address, 'tron', false, lang)),
  };

  const canSubmit = Object.values(inputs).every((input) => input.isValid);

  const handleSubmit = useLastCallback((e: React.FormEvent | React.UIEvent) => {
    stopEvent(e);
    if (!canSubmit) return;

    const addressByChain: ApiImportAddressByChain = {
      ton: inputs.ton.value,
    };
    for (const [chain, { value }] of Object.entries(inputs)) {
      if (value) {
        addressByChain[chain as keyof typeof inputs] = value;
      }
    }

    importReadonlyAccount({ addressByChain });
    onClose();
  });

  return (
    <form onSubmit={handleSubmit}>
      <Input
        {...inputs.ton.inputProps}
        label={lang('%chain% Address', { chain: 'TON' })}
        isRequired
      />
      {!IS_CORE_WALLET && (
        <Input
          {...inputs.tron.inputProps}
          label={lang('%chain% Address (optional)', { chain: 'TRON' })}
        />
      )}

      <div className={modalStyles.buttons}>
        <Button className={modalStyles.button} onClick={onClose}>
          {lang('Close')}
        </Button>
        <Button isPrimary isSubmit isDisabled={!canSubmit} className={modalStyles.button}>
          {lang('Add')}
        </Button>
      </div>
    </form>
  );
}

function useInput(validate: (value: string) => string | undefined) {
  const [value, setValue] = useState('');
  const [isTouched, markTouched] = useFlag(false);
  const validationError = validate(value);

  const onInput = useLastCallback((newValue: string) => {
    markTouched();
    setValue(newValue);
  });

  return {
    value,
    isValid: !validationError,
    inputProps: {
      value,
      onInput,
      error: isTouched ? validationError : undefined,
    },
  };
}

function validateAddressInput(value: string, chain: ApiChain, isRequired: boolean, lang: LangFn) {
  if (!value) {
    if (isRequired) {
      return lang('Required');
    }
    return undefined;
  }
  if (!isValidAddress(value, chain)) {
    return lang('Incorrect address');
  }
  return undefined;
}
